<div class="card-surgery">
  <div class="card-surgery_bg">
    <img [src]="cardSurgeryDto.image" alt="" class="card-surgery__img-bg" />
    <div class="card-surgery__bg-overlay"></div>
  </div>
  <h2 class="card-surgery__title">{{ cardSurgeryDto.title }}</h2>
  <hr class="card-surgery__line" />
  <h3 class="card-surgery__desc">
    {{ cardSurgeryDto.description }}
  </h3>

  <!-- <button class="btn">
    DETALHES
    <svg
      id="icon"
      class="btn__arrow-down"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
        </style>
      </defs>
      <title>align--left</title>
      <rect x="12" y="6" width="15" height="3" />
      <rect x="12" y="12" width="11" height="3" />
      <rect x="12" y="18" width="15" height="3" />
      <rect x="12" y="24" width="11" height="3" />
      <rect x="6" y="4" width="2" height="24" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        class="cls-1"
        width="32"
        height="32"
      />
    </svg>
  </button> -->
</div>
