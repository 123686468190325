<div class="about shadow-overmap" id="about">
  <div class="about__title">
    <h1 class="about__title-h1">Quem Somos</h1>
  </div>
  <section class="about__start">
    <div class="about__start-image">
      <div class="screen">
        <div class="screen__logo">
          <img src="../../../../assets/img/logo.png" alt="" srcset="" />
          <span class="screen__logo-text">OrthoMedical</span>
        </div>
      </div>
      <div class="screen-base"></div>
      <div class="suport"></div>
      <div class="base"></div>
    </div>
    <div class="about__start-description">
      <p>
        A Orthomedical é uma empresa do segmento de produtos hospitalares e
        implantes e que zela pelo profissionalismo e cuidado aos pacientes e
        profissionais cirurgiões. Nosso lema é uma prestação de serviço de
        excelência com materiais de qualidade. Trabalhamos com os melhores
        fabricantes e os melhores fornecedores de materiais para Cirurgia Buco
        Maxilo Facial, Neurocirurgia, Ortopedia e Cirurgia geral.
      </p>
    </div>
  </section>
</div>
