import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-info-window',
  templateUrl: './custom-info-window.component.html',
  styleUrls: ['./custom-info-window.component.scss']
})
export class CustomInfoWindowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
