<footer class="footer">
  <div class="container">
    <div class="footer__address-div">
      <img
        class="footer__pin"
        src="../../../../assets/img/address.svg"
        alt=""
      />
      <div>
        <p class="footer__address">
          Rua Aluísio de Azevedo, 200, Sala 701, Empresarial José Borba
          Maranhão<br />
          Santo Amaro, Recife-PE <br />CEP: 50.100-090
        </p>
      </div>
    </div>
  </div>
</footer>
