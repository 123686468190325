<svg
  id="email-icon"
  xmlns="http://www.w3.org/2000/svg"
  width="32"
  height="32"
  viewBox="0 0 32 32"
>
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }
    </style>
  </defs>
  <title>mail--all</title>
  <polygon
    points="16.59 20.41 20.17 24 20.17 24 16.58 27.59 18 29 23 24 18 19 16.59 20.41"
  />
  <polygon
    points="23.59 20.41 27.17 24 27.17 24 23.58 27.59 25 29 30 24 25 19 23.59 20.41"
  />
  <path
    d="M14,23H4V7.91l11.43,7.91a1,1,0,0,0,1.14,0L28,7.91V17h2V7a2,2,0,0,0-2-2H4A2,2,0,0,0,2,7V23a2,2,0,0,0,2,2H14ZM25.8,7,16,13.78,6.2,7Z"
  />
  <rect
    id="_Transparent_Rectangle_"
    data-name="&lt;Transparent Rectangle&gt;"
    class="cls-1"
    width="32"
    height="32"
  />
</svg>
