import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phone-icon',
  templateUrl: './phone-icon.component.html',
  styleUrls: ['./phone-icon.component.scss']
})
export class PhoneIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
